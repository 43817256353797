body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('./assets/background/allways_bg.jpg');
  background-size: cover;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 500px;
}

p {
  color: #ffffff;
  font-size: 40px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .App-logo {
    height: 200px;
  }

  p {
    font-size: 20px;
  }
}